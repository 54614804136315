// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "circle", "label" ]

  connect() {
    // initially show online
    const renderCameraOnlineStatus = () => {
      //TODO: get variable camera id
      const slug = JSON.parse(this.data.get("context")).slug
      const exists = Object.keys(window.mqttContext.cameras).includes(slug);

      if (!exists) {
        console.log("Camera-Status not found in mqttContext")
        return
      }

      const { status } = window.mqttContext.cameras[slug]

      if (status === undefined || status === "") {
        return
      }

      this.circleTarget.classList.remove("online")
      this.circleTarget.classList.remove("offline")
      this.circleTarget.classList.add(status)

      this.labelTarget.textContent = status
    }
    renderCameraOnlineStatus()

    setInterval(renderCameraOnlineStatus, 1000)
  }
}
