import { Controller } from "stimulus"
import { v4 as uuid } from 'uuid'
import { Client } from 'paho-client'

const mqttConfig = {
  userName: "benni",
  password: "benni91",
  useSSL: true
}

const topicMatchers = {
  cameraOnlineStatus: (t) => {
    return t.match(/^camera\/(.+)\/status$/gm);
  }
}

// mqttConfig without uri
const buildMqttClient = () => {
  const client = new Client("wss://mqtt.stiegel.org:443/ws", 'PICAM-MQTT-' + uuid().slice(-10))

  console.log('[picture-request-controller] Trying to connect to MQTT')
  client.onMessageArrived = (e) => {
    console.log("onMessageArrived", e.topic, e.payloadString)
    // for debugging
    window.mqttContext.receivedMessages.push({ topic: e.topic, message: e.payloadString })
    console.log(window.mqttContext)

    // get camera id from topic
    const cameraId = e.topic.split("/")[1]

    try {
      if (topicMatchers.cameraOnlineStatus(e.topic)) {
        console.log("updating received camera online status")
        window.mqttContext.cameras[cameraId].status = e.payloadString
      }
    } catch (error) {
      console.log("error parsing received topic", error)
    }

    console.log("mqttContext", mqttContext)
  }
  client.onConnectionLost = (e) => { console.log("onConnectionLost", e) }

  return client
}

export default class extends Controller {
  static targets = [ "output" ]

  initialize() {
// initially show online
window.mqttContext = {
  client: buildMqttClient(),
  receivedMessages: [],
  cameras: {
    cam001: {
      status: "offline"
    }
  }
}

window.mqttContext.client.connect({
  ...mqttConfig,
  keepAliveInterval: 10,
  reconnect: false,
  mqttVersion: 3,
  mqttVersionExplicit: true,
  onSuccess: (e) => {
    // TODO: get array with camera ids from view to subscribe to all necessary topics
    // subscribe to all topics
    console.log("onSuccess", e)
    try {
      const slugs = JSON.parse(this.data.get("context")).slugs
      console.log(slugs)
      slugs.forEach(slug => {
        window.mqttContext.client.subscribe(`camera/${slug}/status`, { qos: 1 })
        window.mqttContext.client.subscribe(`camera/${slug}/test`, { qos: 1 })
      })
    } catch (error) {
      console.log("Error when trying to subscribe", error)
    }
  },
  onFailure: (e) => { console.log("onFailure", e) },
})
    console.log("[mqtt-controller] #initialize")
  }

  connect() {
    console.log("[mqtt-controller] #connect")
  }

  publishTest() {
    try {
      window.mqttContext.client.publish("camera/cam001/test", "geht", 1)
    } catch (error) {
      console.log("Error when trying to publish", error)
    }
  }
}
