// // Called once after the initial page has loaded
// document.addEventListener(
//   'turbolinks:load',
//   () => console.log("Called once after the initial page has loaded"),
//   {
//     once: true,
//   },
// );

// // Called after every non-initial page load
// document.addEventListener('turbolinks:render', () => {
//   console.log("Called after every non-initial page load")
// }
// );



export const registerMenuListener = () => document.addEventListener('turbolinks:load', () => {
  var layout   = document.getElementById('layout'),
      menu     = document.getElementById('menu'),
      menuLink = document.getElementById('menuLink'),
      content  = document.getElementById('main');

  if (!menuLink || !menu) {
    // there is no menu we could toggle...
    return
  }

  function toggleClass(element, className) {
      var classes = element.className.split(/\s+/),
          length = classes.length,
          i = 0;

      for(; i < length; i++) {
        if (classes[i] === className) {
          classes.splice(i, 1);
          break;
        }
      }
      // The className is not found
      if (length === classes.length) {
          classes.push(className);
      }

      element.className = classes.join(' ');
  }

  function toggleAll(e) {
      var active = 'active';

      e.preventDefault();
      toggleClass(layout, active);
      toggleClass(menu, active);
      toggleClass(menuLink, active);
  }

  menuLink.onclick = function (e) {
      toggleAll(e);
  };

  content.onclick = function(e) {
      if (menu.className.indexOf('active') !== -1) {
          toggleAll(e);
      }
  };
});
