import registerEventFormHelpers from './event-form'

const registerFormHelpers = () => {
  document.addEventListener('turbolinks:load', () => {
    registerEventFormHelpers()
  })
}

export default registerFormHelpers


