// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { substringBetween } from "../utils"

export default class extends Controller {
  static targets = [ "image" ]

  connect() {
    try {
      const context = JSON.parse(this.data.get("context"))
      const images = context.images
      if (images.length > 0) {
        console.log("images", images)
        this.imageTarget.src = images[0].url
      }
      const lightBox = document.getElementById("light-box")
      const buttonClose = document.getElementById("light-box-button-close")
      buttonClose.addEventListener("click", (e) => {
        // get index in urls array to show image index
        console.log("Close light box")
        lightBox.hidden = true
        // this.imageTarget.hidden = false
      });

      const classes = context.registerListenerToClasses
      classes.forEach(c => {
        const elements = Array.from(document.getElementsByClassName(c))
        console.log("elements found", elements)

        elements && elements.forEach(el => {
          el.addEventListener("click", (e) => {
            // get image id
            const imageId = e.target.dataset.imageId
            // get url by image_id from image context
            const imageUrl = images.filter(x => x.id == imageId)[0].url
            this.imageTarget.src = imageUrl
            // get index in urls array to show image index
            lightBox.hidden = false
            // this.imageTarget.hidden = false
          });
        })
      })
      // register click listener on all images
      // set current picture in Click-Listener
    } catch (error) {
      console.log(error)
    }

  }

  handleImageClick(e) {
    console.log("Clicked on Image!")
    this.imageTarget.hidden = false
  }
}
