import { Controller } from "stimulus"
import { v4 as uuid } from 'uuid'
import { Client } from 'paho-client'

const mqttConfig = {
  userName: "benni",
  password: "benni91",
  useSSL: true
}

// mqttConfig without uri
const buildMqttClient = () => {
  const client = new Client("wss://mqtt.stiegel.org:443/ws", 'PICAM-MQTT-' + uuid().slice(-10))


  return client
}




export default class extends Controller {
  publish() {
    console.log("Trying to request picture", window.mqttContext.client.isConnected())
    console.log("Context: ", JSON.parse(this.data.get("context")).slug)
    try {
      const slug = JSON.parse(this.data.get("context")).slug
      window.mqttContext.client.publish(
        `camera/${slug}/command/request`,
        JSON.stringify({ method: "pictureRequest" }),
        1,
        false
      )
    } catch (error) {
      console.log("Error when trying to publish", error)
    }
  }
}
